<template>
    <div class="Review">
        <div class="wrap">
            <Head />
            <div class="middle">
                <div class="content">
                    <div class="alllist">
                        <!-- 错题本 -->
                        <Card :padding="0" class="list_item">
                            <!-- 加载中 -->
                            <Spin fix v-if="mistakes_Loading">
                                <Icon type="ios-loading" size=18 class="demo-spin-icon-load"></Icon>
                                <div>Loading</div>
                            </Spin>
                            <img src="@/assets/mistakes.png" alt="">
                            <div class="allsubjectlist" v-if="mistakesList.length != 0">
                                <div class="subjectlist" v-for="(item,index) in mistakesList" :key="item.subjectId">
                                    <div class="subjectlist_tit">{{item.title}}</div>
                                    <div class="subjectlist_item" v-for="(item2,index2) in item.knowledgeList" :key="item2.knowledgePointId" @click="ClickWrong(item2.knowledgePointId)">
                                        <div class="item_left">
                                            <div class="mistakes_line"></div>
                                            <div class="item_left_tit">{{item2.title}}</div>
                                        </div>
                                        <div class="item_right">
                                            <img src="@/assets/doexercise.png" alt="">
                                            <span>{{item2.count}}道题目</span>
                                            <Icon type="ios-arrow-forward" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="Ellipsis" v-if="mistakesList.length == 0">
                                <img src="@/assets/statistics_Ellipsis.png" alt="">
                            </div>
                        </Card>

                        <!-- 收藏题目 -->
                        <Card :padding="0" class="list_item">
                            <!-- 加载中 -->
                            <Spin fix v-if="Collect_Loading">
                                <Icon type="ios-loading" size=18 class="demo-spin-icon-load"></Icon>
                                <div>Loading</div>
                            </Spin>
                            <img src="@/assets/favorite.png" alt="">
                            <div class="allsubjectlist" v-if="CollectList.length != 0">
                                <div class="subjectlist" v-for="(item,index) in CollectList" :key="item.subjectId">
                                    <div class="subjectlist_tit">{{item.title}}</div>
                                    <div class="subjectlist_item" v-for="(item2,index2) in item.knowledgeList" :key="item2.knowledgePointId" @click="ClickCollect(item2.knowledgePointId)">
                                        <div class="item_left">
                                            <div class="mistakes_line Collect_line"></div>
                                            <div class="item_left_tit">{{item2.title}}</div>
                                        </div>
                                        <div class="item_right">
                                            <img src="@/assets/doexercise.png" alt="">
                                            <span>{{item2.count}}道题目</span>
                                            <Icon type="ios-arrow-forward" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="Ellipsis" v-if="CollectList.length == 0">
                                <img src="@/assets/statistics_Ellipsis.png" alt="">
                            </div>
                        </Card>

                        <!-- 题目回顾 -->
                        <Card :padding="0" class="list_item">
                            <!-- 加载中 -->
                            <Spin fix v-if="History_Loading">
                                <Icon type="ios-loading" size=18 class="demo-spin-icon-load"></Icon>
                                <div>Loading</div>
                            </Spin>
                            <img src="@/assets/history.png" alt="">
                            <div class="allHistory" v-show="HistoryList.length != 0">
                                <div class="History_list" ref="scrollTable">
                                    <div class="History_item" v-for="(item,index) in HistoryList" :key="item.userPaperId" @click="ClickPapers(item.paperId,item.belongId,item.belongType,item.state,item.userPaperId)">
                                    <div class="item_top">
                                        <div class="mistakes_line" :class="{Collect_line:item.state == 5}"></div>
                                        <div class="item_left_tit">{{item.name}}</div>
                                    </div>
                                    <div class="item_bottom">
                                        <!-- 练习试卷和测试试卷 -->
                                        <div class="item_bottom_left" v-if="item.execType == 1 || item.execType == 5">
                                            <img src="@/assets/doexercise.png" alt="" v-if="item.execType == 1">
                                            <img src="@/assets/point.png" alt="" v-if="item.execType == 5">
                                            <span>共{{item.questionNum}}道题目</span>
                                            <span v-if="item.execType == 5">，总分{{item.score}}分</span>
                                        </div>
                                        <!-- 模考试卷 -->
                                        <div class="item_bottom_left mokao" v-if="item.execType == 10">
                                            <img src="@/assets/mock_exam_doexercise.png" alt="">
                                            <span>{{item.questionNum}}题</span>
                                            <img src="@/assets/mock_exam_point.png" alt="">
                                            <span>{{item.score}}分</span>
                                            <img src="@/assets/mock_exam_time.png" alt="">
                                            <span>{{item.limitTime}}分钟</span>
                                        </div>
                                        <div class="item_bottom_right">
                                            <Tag color="gold" v-if="item.state == 5">进行中</Tag>
                                            <Tag color="green" v-if="item.state !=5 && item.execType == 1">正确率：{{item.thisRightRate}}%</Tag>
                                            <Tag color="green" v-if="item.state !=5 && item.execType != 1">得分：{{item.getScore}}分</Tag>
                                        </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                            <div class="history_Ellipsis" v-if="CollectList.length == 0">
                                <img src="@/assets/report_Ellipsis.png" alt="">
                                <p>您还没有做过题哦</p>
                            </div>
                        </Card>
                    </div>
                </div>
            </div>
        </div>
        <footer class="footer">
            <iCopyright />
        </footer>
        <!-- 右下角弹窗 -->
        <div class="fixed_btn">
            <div class="contactkf" @click="ClickKf">
                <img src="@/assets/contactkf.png" alt="">
            </div>
            <div class="diss" @click="ClickDiss" >
                <img src="@/assets/diss.png" alt="">
            </div>
        </div>
        <!-- 联系客服弹窗 -->
        <Kfmodal ref="Click_kf"/>
        <!-- 我要吐槽弹窗 -->
        <Dissmodal ref="Click_Diss" :title="Diss_title"/>
    </div>
</template>

<script>
// 页头
import Head from "@/components/head"
// 页脚
import iCopyright from "@/components/footer"
// 联系客服弹窗
import Kfmodal from "@/components/kfmodal"
// 我要吐槽弹窗
import Dissmodal from "@/components/Dissmodal"
import { GetWrongQuestionCount, GetCollectQuestionCount, GetUserPaperList} from "@/api/Review"
export default {
    name: 'Review',
    props: {},
    components: {
        Head,
        iCopyright,
        Kfmodal,
        Dissmodal,
    },
    data () {
       return {
            // 错题本列表
            mistakesList: [],
            mistakes_Loading: false,
            // 收藏题目列表
            CollectList: [],
            Collect_Loading: false,
            HistoryList: [],
            History_Loading: false,
            page: 1,
            totalPages: 0,
            Diss_title: "题目反馈"
       }
    },
    created () {
        this.GetWrongQuestion()
        this.GetCollectQuestion()
        this.GetUserPaperList()
    },
    mounted () {
        // 做题历史下拉触底事件
        this.$refs.scrollTable.addEventListener( 'scroll',(event) => {
            this.getDistance(event);
        });
    },
    methods: {
        // 错题本-获取模块统计
        GetWrongQuestion(){
            this.mistakes_Loading = true
            GetWrongQuestionCount().then((res) =>{
                if(res.ret == 1){
                    this.mistakesList = res.data
                }else{
                    console.log(res.msg)
                }
                this.mistakes_Loading = false
            })
            .catch(err => {
                this.mistakes_Loading = false
            });
        },

        // 收藏题目-获取模块统计
        GetCollectQuestion(){
            this.Collect_Loading = true
            GetCollectQuestionCount().then((res) =>{
                if(res.ret == 1){
                    this.CollectList = res.data
                }else{
                    console.log(res.msg)
                }
                this.Collect_Loading = false
            })
            .catch(err => {
                this.Collect_Loading = false
            });
        },

        // 获取做题历史
        GetUserPaperList(){
            this.History_Loading = true
            GetUserPaperList(this.page).then((res) =>{
                if(res.ret == 1){
                    // 接口返回新数组
                    const newdataList = res.data
                    // 获取data中的旧数组
                    const beforedataList = this.HistoryList
                    // 总页数
                    this.totalPages = res.extends.totalPages
                    this.HistoryList = [...beforedataList, ...newdataList]
                }else{
                    console.log(res.msg)
                }
                this.History_Loading = false
            })
            .catch(err => {
                this.History_Loading = false
            });
        },

        // 做题历史下拉触底事件
        getDistance(event) {
            let dom = event.target;
            const scrollDistance =dom.scrollHeight - dom.scrollTop - dom.clientHeight
                if(scrollDistance <=0){//等于0证明已经到底，可以请求接口
                    // do something
                    // 判断有没有下一页数据
				    if (this.page >= this.totalPages) {
				    	// 没有下一页
				    	// console.log('没有下一页')
				    } else {
				    	// 有下一页数据
				    	this.page++;
				    	this.GetUserPaperList()
				    }
                }
        },

        // 点击错题本
        ClickWrong(knowledgePointId){
            this.$router.push({path: '/WrongTopic',query: {id: knowledgePointId}})
        },

        // 点击收藏题目
        ClickCollect(knowledgePointId){
            this.$router.push({path: '/CollectTopic',query: {id: knowledgePointId}})
        },

        // 点击做题历史
        ClickPapers(paperId,belongId,belongType,state,userPaperId){
            this.$router.push({path: '/QuestionPapers',query: {paperId: paperId,belongId: belongId,belongType: belongType,state: state,userPaperId: userPaperId}})
        },

        // 点击我要吐槽
        ClickDiss(){
            this.$refs.Click_Diss.ClickDissmodal()
        },

        // 点击联系客服
        ClickKf(){
            this.$refs.Click_kf.Click_kf()
        }
    },
}
</script> 

<style lang='less' scoped>
    .Review{
        display: flex;
        flex-flow: column;
        min-height: 100vh;
        background-color: #f8f8f8;
        .middle{
            display: flex;
            -webkit-box-pack: center;
            justify-content: center;
            min-width: 1200px;
            .content{
                width: 1200px;
                text-align: left;
                .alllist{
                    padding: 70px 0 20px;
                    display: flex;
                    justify-content: space-between;
                    .list_item{
                        width: 393px;
                        height: 688px;
                        background: #FFFFFF;
                        border-radius: 16px;
                        position: relative;
                        padding-bottom: 10px;
                        .allsubjectlist{
                            width: 100%;
                            height: 581px;
                            overflow: hidden;
                            overflow-y: auto;
                            .subjectlist{
                                margin-bottom: 30px;
                                padding: 0 20px;
                                .subjectlist_tit{
                                    font-size: 18px;
                                    font-weight: bold;
                                    color: #333333;
                                    margin-bottom: 17px;
                                }
                                .subjectlist_item{
                                    height: 44px;
                                    background: #F8F9FA;
                                    border-radius: 8px;
                                    margin-bottom: 10px;
                                    display: flex;
                                    justify-content: space-between;
                                    align-items: center;
                                    padding-right: 10px;
                                    cursor: pointer;
                                    .item_left{
                                        display: flex;
                                        align-items: center;
                                        .mistakes_line{
                                            width: 4px;
                                            height: 16px;
                                            background: #5C85FF;
                                            border-radius: 0px 3px 3px 0px;
                                        }
                                        .Collect_line{
                                            background: #FFC021;
                                        }
                                        .item_left_tit{
                                            font-size: 16px;
                                            font-weight: 400;
                                            color: #333333;
                                            margin-left: 10px;
                                        }
                                    }
                                    .item_right{
                                        display: flex;
                                        align-items: center;
                                        span{
                                            margin: 0 6px;
                                        }
                                    }
                                }
                                .subjectlist_item:last-child{
                                    margin-bottom: 0px;
                                }
                            }
                        }
                        .allHistory{
                            padding: 0 10px 10px;
                            width: 100%;
                            height: 581px;
                            .History_list{
                                height: 100%;
                                background: #F8F9FA;
                                border-radius: 2px;
                                padding: 10px;
                                overflow: hidden;
                                overflow-y: auto;
                                .History_item{
                                    background: #FFFFFF;
                                    border-radius: 8px;
                                    margin-bottom: 10px;
                                    padding: 13px 0;
                                    cursor: pointer;
                                    .item_top{
                                        display: flex;
                                        align-items: center;
                                        .mistakes_line{
                                            width: 4px;
                                            height: 16px;
                                            background: #FFC021;
                                            border-radius: 0px 3px 3px 0px;
                                        }
                                        .Collect_line{
                                            background: #E30000;
                                        }
                                        .item_left_tit{
                                            font-size: 16px;
                                            font-weight: 400;
                                            color: #333333;
                                            margin-left: 10px;
                                        }
                                    }
                                    .item_bottom{
                                        margin-top: 15px;
                                        display: flex;
                                        justify-content: space-between;
                                        align-items: center;
                                        padding: 0 7px 0 17px;
                                        .item_bottom_left{
                                            display: flex;
                                            align-items: center;
                                            img{
                                                margin-right: 5px;
                                            }
                                            span{
                                                margin-right: 10px;
                                                font-size: #333333;
                                            }
                                        }
                                        .mokao{
                                            color: #999999;
                                        }
                                    }
                                }
                                .History_item:last-child{
                                    margin-bottom: 0px;
                                }
                            }
                            .History_list::-webkit-scrollbar {
                              width: 5px;
                              height: 15px;
                            }
                            .History_list::-webkit-scrollbar-thumb {
                              border-radius: 10px;
                              background-color: #fff;
                              border: 1px solid #DDDDDD;
                            }
                            .History_list::-webkit-scrollbar-track {
                              box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
                              background: #ededed;
                              border-radius: 10px;
                            }
                        }
                        .allsubjectlist::-webkit-scrollbar {
                          width: 5px;
                          height: 15px;
                        }
                        .allsubjectlist::-webkit-scrollbar-thumb {
                          border-radius: 10px;
                          background-color: #fff;
                          border: 1px solid #DDDDDD;
                        }
                        .allsubjectlist::-webkit-scrollbar-track {
                          box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
                          background: #ededed;
                          border-radius: 10px;
                        }
                        .Ellipsis{
                            text-align: center;
                            margin-top: 150px;
                        }
                        .history_Ellipsis{
                            text-align: center;
                            margin-top: 150px;
                            font-size: 16px;
                            font-family: PingFang SC;
                            font-weight: bold;
                            color: #888888;
                            p{
                                margin-top: 30px;
                            }
                            img{
                                width: 300px;
                                height: 180px;
                            }
                        }
                    }
                    .analyse_Ellipsis{
                        text-align: center;
                        padding: 78px 0 74px;
                        font-size: 16px;
                        font-weight: 400;
                        color: #999999;
                    }
                }
            }
        }
    }
    .wrap{
        flex: 1;
    }
    // 右下角按钮
    .fixed_btn{
        position: fixed;
        right: 40px;
        bottom: 92px;
        img{
            width: 80px;
            height: 80px;
        }
    }
    // 加载中
    .demo-spin-icon-load{
        animation: ani-demo-spin 1s linear infinite;
    }
    @keyframes ani-demo-spin {
        from { transform: rotate(0deg);}
        50%  { transform: rotate(180deg);}
        to   { transform: rotate(360deg);}
    }
    .demo-spin-col{
        height: 100px;
        position: relative;
        border: 1px solid #eee;
    }
</style>
